export const theme = {
    global: {
        colors: {
            brand: "#304ffe",
            brandLight: "#7a7cff",
            brandDark: "#0026ca",
            secondary: "#d50000",
            secondaryLight: "#ff5131",
            secondaryDark: "#9b0000",

        }
    }
}